import about from './about.png'
import about_focused from './about_focused.png'
import service from './service.png'
import service_focused from './service_focused.png'
import cases from './case.png'
import cases_focused from './case_focused.png'
import join from './join.png'
import join_focused from './join_focused.png'

export default {
  about,
  about_focused,
  service,
  service_focused,
  cases,
  cases_focused,
  join,
  join_focused
}
