import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import NavBar from "../../components/navBar";
import TabBar from "../../components/tabBar";
import Footer from "../components/footer";
import About from "../views/about";
import Case from "../../window/views/case";
import Join from "../../window/views/join";
import Service from "../components/service";
import Search from "../../window/views/service/search";
import './app.scss'
import Content from "../../window/views/service/content";
import Private from "../../window/views/service/private";
import Media from "../../window/views/service/media";
const RouterContainer = () => {
  return (
    <Router>
      <div className={'wap-app'}>
        {/* <NavBar /> */}
        <div className={'wap-body'}>
          <Switch>
            <Route exact path="/about" component={About}/>
            {/*<Route exact path="/about" component={About}/>*/}
            <Route path="/case/:name">
              <Case isMobile={true}/>
            </Route>
            <Route path="/content" >
              <Service>
                <Content isMobile={true}/>
              </Service>
            </Route>
            <Route path="/content?id=ulId1" >
              <Service>
                <Content isMobile={true}/>
              </Service>
            </Route>
            <Route path='/content?id=dataValue'>
              <Service>
                <Content isMobile={true} />
              </Service>
            </Route>
            <Route path='/content?id=ulId1'>
              <Service>
                <Content isMobile={true}/>
              </Service>
            </Route>
            {/* <Route path='/media'>
              <Service>
                <Media isMobile={true}/>
              </Service>
            </Route> */}
            <Route exact path="/join">
              <Join isMobile={true}/>
            </Route>
            <Redirect from={'/'} to={'/about'}/>
          </Switch>
          <Footer />
        </div>
        <TabBar />
      </div>
    </Router>
  )
}

export default RouterContainer
