import search from './search.png'
import search_focused from './search_focused.png'
import content from './content.png'
import content_focused from './content_focused.png'
import privacy from './privacy.png'
import privacy_focused from './privacy_focused.png'
import media_focused from './media_focused.png'
import media from './media.png'

export default {
  search,
  search_focused,
  content,
  content_focused,
  privacy,
  privacy_focused,
  media_focused,
  media
}
