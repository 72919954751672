import { useEffect, useState } from "react";
import Window from './window/router'
import Mobile from './mobile/router'
import { isMobile as IS_MOBILE } from './utils/screen'
import './App.css';
import 'antd/dist/antd.css';
function App() {
  const [isMobile, setIsMobile] = useState(IS_MOBILE(window.innerWidth, window.innerHeight))
  useEffect(() => {
    window.addEventListener('resize',(e)=>{
      setIsMobile(IS_MOBILE(e.target.innerWidth, e.target.innerHeight))
    });
  }, [])
  console.log(isMobile, 'isMobile')
  localStorage.setItem('isMobile',isMobile)
  return isMobile ? <Mobile isMobile={isMobile}/> :<Window isMobile={isMobile}/>
}

export default App;
