import { useEffect } from "react";
import topTab from "../../../assets/icon/tab/topTab";
import './service.scss'
import {useHistory} from "react-router-dom";

const Service = (
  {
    children
  }
) => {
  const history = useHistory()
  const tabs = [
    // {
    //   title: '内容价值',
    //   icon: 'search',
    //   path: '/content?id=ulId4'
    // },
    // {
    //   title: '数据价值',
    //   icon: 'content',
    //   path: '/content?id=dataValue'
    // },
    // {
    //   title: '渠道价值',
    //   icon: 'privacy',
    //   path: '/content?id=ulId1'
    // },
    {
      title: '内容价值',
      icon: 'search',
      path: '/content?id=dataValue'
    },
    {
      title: '数据价值',
      icon: 'content',
      path: '/content?id=ulId1'
    },
    {
      title: '渠道价值',
      icon: 'privacy',
      path: '/content?id=ulId4'
    },
    // {
    //   title: '媒体资源',
    //   icon: 'media',
    //   path: '/media'
    // },
  ]
  return (
    <>
      <div className={'top-tab'}>
        {
          tabs.map((item, index) =>
            {
              let search = '?'+item.path.split('?')
              const focused = window.location.search === search
              return (
                  <div className={'top-tab-item'} onClick={() => history.push(item.path)} key={index}>
                    <img src={focused ? topTab[`${item.icon}_focused`] : topTab[item.icon]} alt=""/>
                    <p style={{ color: focused ? '#000' : '#686868' }}>{item.title}</p>
                  </div>
              )
            }
          )
        }
      </div>
      {children}
    </>
  )
}

export default Service
