import intro from '../../../../assets/images/case/shanghai/intro.png'
import content from '../../../../assets/images/case/shanghai/content.png'
import activity from '../../../../assets/images/case/shanghai/activity.png'
import official from '../../../../assets/images/case/shanghai/official.png'
import live from '../../../../assets/images/case/shanghai/live.png'
import electricity from '../../../../assets/images/case/shanghai/electricity.png'

import './shyz.scss'
const LISTS = [
  {
    label: '官方运营',
    cover: official,
  },
  {
    label: '活动运营',
    cover: activity,
  },
  {
    label: '直播合作',
    cover: live,
  },
  {
    label: '内容种草',
    cover: content,
  },
  {
    label: '电商运营',
    cover: electricity,
  },
]
const Shyz = ({isMobile}) => {
  return (
    <div className={'shyz'} style={{ padding: isMobile ? '20px 2rem' : '30px 200px' }}>
      <img src={intro} alt="" className={'intro'} draggable={"false"}/>
      <div className={'list'} style={{ marginBottom: isMobile ? '2rem' : '100px', marginTop: isMobile ? '2rem' : '60px'  }}>
        {
          LISTS.map((item, index) =>
            <div className={'listItem'} key={index} style={{ marginTop: isMobile ? '2rem' : '60px'}}>
              <img src={item.cover} alt="" style={{ height: isMobile ? '12rem' : '700px' }} draggable={"false"}/>
              <p>{item.label}</p>
            </div>
          )
        }
      </div>
    </div>
  )
}


export default Shyz
