import banner from "../../../../assets/images/service/content/banner.png";
import contentMarketing1 from "../../../../assets/images/service/content/contentMarketing3.jpg";
import neirong from "../../../../assets/images/service/content/mainimg/neirong.jpg";

import dataValue from "../../../../assets/images/service/content/mainimg/dataValue.jpg";
import thirdPartyPlatform from "../../../../assets/images/service/content/mainimg/thirdPartyPlatform.jpg";
import researchData from "../../../../assets/images/service/content/mainimg/researchData.jpg";
import relyingTechnology from "../../../../assets/images/service/content/mainimg/relyingTechnology.jpg";
import channelValue from "../../../../assets/images/service/content/mainimg/channelValue.jpg";
import bigFfff from "../../../../assets/images/service/content/mainimg/bigFfff2.png";
import redBull from "../../../../assets/images/service/content/mainimg/redBull2.png";
import nineDour from "../../../../assets/images/service/content/mainimg/nineDour2.png";
import sweetWord from "../../../../assets/images/service/content/mainimg/sweetWord2.png";
import goodTea from "../../../../assets/images/service/content/mainimg/goodTea2.png";
import "./content.scss";
import { Carousel, Modal } from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import React, { Component, useState } from "react";
import { Children } from "react/cjs/react.production.min";
import { Prompt } from "react-router";
class Content extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);

    this.state = {
      isPush: true,
      isShow: true,
      isModalVisible: false,
      video: "",
      isMobile: false,
      autoPlayer: false,
      newList: [
        {
          imgsrc: dataValue,
        },
        {
          imgsrc: thirdPartyPlatform,
        },
        {
          imgsrc: researchData,
        },
        {
          imgsrc: relyingTechnology,
        },
        {
          imgsrc: channelValue,
        },
        
      ],
      swiperList: [
        {
          imgsrc: bigFfff,
          vediosrc: "https://www.huanzeit.com/bigFfff.mp4",
        },
        {
          imgsrc: redBull,
          vediosrc: "https://www.huanzeit.com/redBull.mp4",
        },
        {
          imgsrc: goodTea,
          vediosrc: "https://www.huanzeit.com/goodTea.mp4",
        },
        {
          imgsrc: sweetWord,
          vediosrc: "https://www.huanzeit.com/sweetWord.mp4",
        },
        {
          imgsrc: nineDour,
          vediosrc: "https://www.huanzeit.com/nineDour.mp4",
        },
      ],
    };
  }
  next() {
    this.setState(
      {
        video: document.querySelector(
          "#play" + this.slider.innerSlider.state.currentSlide
        ),
        isPush: false,
      },
      () => {
        console.log(111);
        this.state.video.pause();
      }
    );
    this.slider.next();
    this.setState({
      isShow: true,
    });
  }

  prev() {
    this.setState(
      {
        video: document.querySelector(
          "#play" + this.slider.innerSlider.state.currentSlide
        ),
        isPush: false,
      },
      () => {
        this.state.video.pause();
      }
    );
    this.slider.prev();
    this.setState({
      isShow: true,
    });
  }
  open() {
    this.setState(
      {
        video: document.querySelector(
          "#play" + this.slider.innerSlider.state.currentSlide
        ),
        isPush: false,
      },
      () => {
        this.state.video.play();
      }
    );
    this.setState({
      isShow: false,
    });
  }
  componentDidMount() {
    this.setState({
      isMobile: JSON.parse(localStorage.getItem("isMobile")),
    });
  }
  render() {
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
      autoplay: false,
    };
    let search = window.location.search.split("=")[1];
    setTimeout(() => {
      if (this.state.isPush == true) {
        if (search != "dataValue") {
          let temp = document.querySelector("#ulId");
          if (search == "ulId1") {
            return temp.getElementsByTagName("li")[0].scrollIntoView();
          } else if (search == "ulId4") {
            return temp.getElementsByTagName("li")[4].scrollIntoView();
          }
        } else if (search == "dataValue") {
          return document.getElementById("dataValue").scrollIntoView();
        }
      }
    }, 0);
    return (
      <div className={"content"}>
        <Prompt
          message={() => {
            return this.setState(
              {
                video: document.querySelector(
                  "#play" + this.slider.innerSlider.state.currentSlide
                ),
              },
              () => {
                this.state.video.pause();
              }
            );
          }}
        ></Prompt>
        <img src={banner} alt="" className={"banner"} />
        <div
          className={`container ${this.state.isMobile ? "wap-container" : ""}`}
        >
          <img
            src={contentMarketing1}
            alt=""
            draggable={"false"}
            id={"dataValue"}
          />
          <img src={neirong} alt="" draggable={"false"} />
          <div className={"container-img"}>
            <LeftCircleOutlined
              className={`leftLine ${
                this.state.isMobile ? "wap-leftLine" : ""
              }`}
              onClick={this.prev}
            />
            <Carousel
              className={"carousel"}
              {...lunboSetting}
              ref={(el) => (this.slider = el)}
              dots={false}
              infinite
            >
              {this.state.swiperList.map((item, index) => (
                <div key={item.imgsrc} className={"container-img-vedio"}>
                  <img
                    className={`contentStyle ${
                      this.state.isMobile ? "wap-contentStyle" : ""
                    }`}
                    src={item.imgsrc}
                    alt=""
                    key={item.imgsrc}
                  />
                  <div className="videoStyle">
                    <video
                      className={`vedioStyle ${
                        this.state.isMobile ? "wap-vedioStyle" : ""
                      }`}
                      id={"play" + index}
                      src={item.vediosrc}
                      controls
                      type="video/mp4"
                    ></video>
                    {/* {this.state.isShow && this.state.isMobile == false ? (
                      <div className={"player"}>
                        <PlayCircleOutlined
                          style={{
                            fontSize: "160px",
                            color: "#fff",
                            fontWeight: "100",
                            textAlign: "center",
                          }}
                          onClick={this.open.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              ))}
            </Carousel>
            <RightCircleOutlined
              className={`rightLine ${
                this.state.isMobile ? "wap-rightLine" : ""
              }`}
              onClick={this.next}
            />
          </div>
          <ul id={"ulId"}>
            {this.state.newList.map((item, index) => (
              <li key={index}>
                <img src={item.imgsrc} alt="" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(Content);
