import detail_one from '../../../../assets/images/case/aptamil/detail_one.png'
import detail_two from '../../../../assets/images/case/aptamil/detail_two.png'
import detail_three from '../../../../assets/images/case/aptamil/detail_three.png'
import './aptamil.scss'
const Aptamil = ({isMobile}) => {
  return (
    <div className={'aptamil'} style={{ padding: isMobile ? '20px 2rem' : '30px 200px' }}>
      <img src={detail_one} alt="" draggable={"false"}/>
      <img src={detail_two} alt="" draggable={"false"}/>
      <img src={detail_three} alt="" draggable={"false"}/>
    </div>
  )
}

export default Aptamil
