import detail_one from '../../../../assets/images/case/grohe/detail_one.png'
import detail_two from '../../../../assets/images/case/grohe/detail_two.png'
import detail_three from '../../../../assets/images/case/grohe/detail_three.png'
import './grohe.scss'
const Grohe = ({isMobile}) => {
  return (
    <div className={'grohe'} style={{ padding: isMobile ? '20px 2rem' : '30px 200px' }}>
      <img src={detail_one} alt="" draggable={"false"}/>
      <img src={detail_two} alt="" draggable={"false"}/>
      <img src={detail_three} alt="" draggable={"false"}/>
    </div>
  )
}

export default Grohe
