import banner from "../../../../assets/images/service/private/banner.png";
import redBook from "../../../../assets/images/service/private/redBook.png";
import wechat from "../../../../assets/images/service/private/wechat.png";
import tiktok from "../../../../assets/images/service/private/tiktok.png";
import './pricvate.scss'

const Private = ({isMobile = false}) => {

  return (
    <div className={'private'}>
      <img src={banner} alt="" className={'banner'} draggable={"false"}/>
      <div className={`container ${isMobile ? 'wap-container' : ''}`}>
        <img src={redBook} alt="" draggable={"false"}/>
        <img src={wechat} alt="" draggable={"false"}/>
        <img src={tiktok} alt="" draggable={"false"}/>
      </div>
    </div>
  )
}

export default Private
