const os = () => {
  const ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isWechat = /(?:iMicroMessenger)/.test(ua),
        isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;
  return {
    isTablet,
    isPhone,
    isAndroid,
    isPc
  }
}

const isMobile = (width, height) => {
  const {isTablet, isPhone, isPc, isAndroid, isWechat} = os()
  if( isAndroid || isPhone ){
    return true
  }

  if(isTablet){
    if(width < height){
      return true
    }
  }
  if(isWechat){
    if(width <= 1024){
      return true
    }
  }
  if(isPc){
    if(width <= 768){
      return true
    }
  }
  return false
}

export {
  os,
  isMobile
}
