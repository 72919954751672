import aboutTop from '../../../assets/images/about/top.png'
import Subtitle from "../../../components/subtitle";
import about from '../../../assets/images/about/about.png'
import core from '../../../assets/images/about/core.png'
import partners from '../../../assets/images/about/partners.png'
import contact from '../../../assets/images/about/contact.png'
import aboutCp from '../../../assets/images/about/aboutCp.png'
import aboutHz from '../../../assets/images/about/aboutHz1.jpg'
import aboutJs from '../../../assets/images/about/aboutJs1.jpg'
import aboutJs2 from '../../../assets/images/about/aboutJs2.jpg'
import aboutJs3 from '../../../assets/images/about/aboutJs3.jpg'
import chatme from '../../../assets/images/about/window.png'

import { withRouter } from "react-router-dom";
import React, { Component, useState } from "react";
// import chatme from '../../../assets/images/about/chatme.jpg'

import './about.scss'

class About extends Component {
    changePages() {
        // console.log(111);
        window.open('http://www.shauetech.com/')
    }
    render() {
        return (
            <div className={'about'}>
                <div className={'about-banner-container'}>
                    <div className={'about-banner-title-container'}>
                        <div className={'about-banner-title'}>
                            <p className={'title'}>数据赋能的内容营销服务商</p>
                            <p className={'subtitle'}>专注于提升客户品牌价值</p>
                        </div>
                    </div>
                    <img src={aboutTop} alt="" style={{ width: '100%' }} draggable={"false"} />
                </div>
                <div className={'partners'}>
                    <img src={aboutHz} alt="" draggable={"false"} />
                </div>
                <div onClick={this.changePages} className={'partners'}>
                    <img src={aboutJs2} alt="" draggable={"false"} />
                </div>
                <div className={'partners'}>
                    <img src={aboutJs3} alt="" draggable={"false"} />
                </div>
                <div className={'partners'}>
                    <img src={aboutCp} alt="" draggable={"false"} />
                </div>
                <div className={'partners'}>
                    <img src={chatme} alt="" draggable={"false"} />
                </div>
                {/* <Subtitle/> */}
                {/* <div className={'container intro'}>
                <p className={'introContent'}>焕泽是一家在大数据和社交网络时代为企业智慧经营赋能的数据营销科技公司，业务整合了信息技术营销服务、数字广告、综合性数字内容创造三大优势业务板块，基于消费者洞察，以自建的大数据平台为基石，通过数字营销赋能产品价值，重塑品牌的数字化影响力</p>
                <div className={'introImg'}>
                    <img src={about} alt=""/>
                </div>
            </div> */}
                {/* <div className={'core-img-container'}>
                <h2 className={'core-title'}>核心技术</h2>
                <p className={'core-subtitle'}>
                    <span className={'core-subtitle-top'}>依托大数据，一站式内容营销体系 </span>
                    <br/>
                    运用核心科创技术，贯彻营销传播各层环节，提供全面的行业、<br/>
                    用户洞察和效果监测，快速捕捉需求，并实现流量转化。
                </p>
                <img src={core} alt="" style={{width: '100%', marginBottom: 30}} draggable={"false"}/>
            </div> */}
                {/* <Subtitle en={'PARTNERS'} title={'合作客户'}/> */}
                {/* <div className={'partners'}>
                <img src={partners} alt="" draggable={"false"}/>
            </div> */}
                {/* <div className={'contact-info-container'}>
                <div className={'contact-info-detail'}>
                    <ul className={'contact-info-list'}>
                        {
                            contactList.map((item, index) =>
                                <li key={index}>
                                    <p>{item.title}</p>
                                    <p>{item.detail}</p>
                                </li>
                            )                            }
                    </ul>
                    <h1>联系我们</h1>
                </div>
                <img src={contact} alt="" draggable={"false"}/>
            </div> */}
            </div>
        )
    }
}

export default About
