import subject from '../../../assets/images/case/subject.png'
import banner from '../../../assets/images/case/banner.png'
import './case.scss'
import groheLogo from '../../../assets/images/case/grohe/logo.png'
import groheCover from '../../../assets/images/case/grohe/cover.png'
import aptamilCover from '../../../assets/images/case/aptamil/cover.png'
import aptamilLogo from '../../../assets/images/case/aptamil/logo.png'
import shyzLogo from '../../../assets/images/case/shanghai/logo.png'
import shyzCover from '../../../assets/images/case/shanghai/cover.png'
import {Link, useParams} from "react-router-dom";
import Grohe from "./grohe";
import Aptamil from './aptamil'
import Shyz from "./shyz";
const CASES = [
  {
    name: 'grohe',
    title: '搜索营销',
    intro: '高仪卫浴 知乎内容＋搜索优化',
    logo: groheLogo,
    cover: groheCover
  },
  {
    name: 'aptamil',
    title: '内容营销',
    intro: '爱他美新品口碑营销',
    logo: aptamilLogo,
    cover: aptamilCover
  },
  {
    name: 'shyz',
    title: '私域营销',
    intro: '上海药皂 小红书号店一体运营',
    logo: shyzLogo,
    cover: shyzCover
  },
]

const Case = (
  {
    isMobile = false
  }
) => {
  const { name } = useParams();
  switch (name) {
    case 'grohe':
      return <Grohe isMobile={isMobile}/>
    case 'aptamil':
      return <Aptamil isMobile={isMobile}/>
    case 'shyz':
      return <Shyz isMobile={isMobile}/>
    default:
      return <CaseIndex isMobile={isMobile}/>
  }
}

const CaseIndex = (
  { isMobile }
) => {
  return(
    <div className={'case'}>
      <img src={banner} alt="" className={'banner'} draggable={"false"}/>
      <div className={'subject'}>
        <img src={subject} alt="" draggable={"false"}/>
      </div>
      <div className={'caseContainer'} style={{ padding: isMobile ? '20px 2rem' : '30px 200px' }}>
        {
          CASES.map((item, index) => (
            <div className={'caseItem'} key={index}>
              <img src={item.cover} alt="" draggable={"false"} />
              <div className={`bottom ${isMobile ? 'wap-bottom' : ''}` }>
                <div className={'introContainer'}>
                  <img src={item.logo} alt="" className={'logo'} draggable={"false"}/>
                  <div className={'introContent'}>
                    <p className={'title'}>{item.title}</p>
                    <p className={'content'}>{item.intro}</p>
                    {/*<div className={ isMobile ? 'wap-title' : ''}>*/}
                    {/*  <span className={'content-text'}>{item.intro}</span>*/}
                    {/*  <span className={'content'} title={item.intro} >{item.intro}</span>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className={'button'}>
                  <Link to={`/case/${item.name}`}>点击查看</Link>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Case
