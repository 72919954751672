import './home.scss'

const Home = () => {
  return (
    <div className={'home'}>
      <div className={'homeContent'}>
        <div>
          <p className={'title'}>数据赋能的</p>
          <p className={'second'}>内容营销服务商</p>
          <p className={'third'}>专注于提升客户品牌价值</p>
        </div>
      </div>
      <footer>
        <p className={'copyright'}>
          Copyright © 上海焕泽信息技术有限公司, All Rights Reserved.
          <br/>
          <a href="https://beian.miit.gov.cn/">沪ICP备2021014392号-1</a>
        </p>
      </footer>
    </div>
  )
}

export default Home
