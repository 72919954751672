import './footer.scss'

const Footer = () => {

  return (
    <footer className={'wap-footer'}>
      <p className={'wap-footer-info'}>
        上海焕泽信息技术有限公司 <br/>
        联系我们：13564208710 <br/>
        业务合作：xuduozhi@shuhengio.com <br/>
        Copyright © 上海焕泽信息技术有限公司, All Rights Reserved.<br/>
        <a href="https://beian.miit.gov.cn/">沪ICP备2021014392号-1</a></p>
    </footer>
  )
}
export default Footer
