import session from './session';

const Jd = {
    GetList: () => session.get('api/jd'),
}


export default {
    Jd
}
