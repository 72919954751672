import bg from '../../../assets/images/aboutTopBg.png'
import Subtitle from "../../../components/subtitle";
import './about.scss'
import core from "../../../assets/images/aboutCore.png";
import about from "../../../assets/images/about/about.png";
import subject from '../../../assets/images/about/subject.png'
import partners from "../../../assets/images/about/partners.png";
import contact from "../../../assets/images/aboutContact.png";

import aboutCp from '../../../assets/images/appimg/aboutApp.jpg'
import aboutHz from '../../../assets/images/about/aboutHz.png'
import aboutJs from '../../../assets/images/appimg/aboutJs.jpg'
import aboutM from '../../../assets/images/appimg/aboutM.jpg'
// import chatme from '../../../assets/images/about/chatme.jpg'
import chatme from '../../../assets/images/about/mobile.png'
import aboutMobile from '../../../assets/images/appimg/aboutHz.jpg'


const contactList = [
    {
        title: '地址',
        detail: '上海浦东新区银冬路125号 华金资产大楼 1楼'
    },
    {
        title: '电话',
        detail: '13564208710'
    },
    {
        title: '邮箱',
        detail: 'xuduozhi@shuhengio.com'
    },
    {
        title: '联系人',
        detail: 'Li longFei'
    }
]

const About = () => {

    return (
        <div className={'wap-about'}>
            <div className={'wap-about-top'}>
                <img src={bg} alt=""/>
                <div className={'wap-about-top-title-container'}>
                    <div className={'wap-about-top-title-container-center'}>
                        <p className={'wap-about-top-title'}>数据赋能的</p>
                        <p className={'wap-about-top-second'}>内容营销服务商</p>
                        <p className={'wap-about-top-third'}>专注于提升客户品牌价值</p>
                    </div>
                </div>
            </div>
            {/* <Subtitle/> */}
            {/* <div className={'wap-about-intro'}>
                <p className={'wap-about-intro-title'}>
                    焕泽是一家在大数据和社交网络时代 <br/>
                    为企业智慧经营赋能的数据营销科技公司，<br/>
                    业务整合了 <br/>
                    信息技术营销服务、数字广告、综合性数字内容创造三大优势业务板块，<br/>
                    基于消费者洞察，以自建的大数据平台为基石，<br/>
                    通过数字营销赋能产品价值，重塑品牌的数字化影响力</p>
                <img src={about} alt=""/>
            </div>
            <div className={'wap-core-img-container'}>
                <h2 className={'wap-core-title'}>核心技术</h2>
                <p className={'wap-core-subtitle'}>
                    <span className={'wap-core-subtitle-top'}>依托大数据，一站式内容营销体系 </span>
                    <br/>
                    运用核心科创技术，贯彻营销传播各层环节，提供全面的行业、<br/>
                    用户洞察和效果监测，快速捕捉需求，并实现流量转化。
                </p>
                <img src={core} alt="" style={{width: '100%', marginBottom: 30}} draggable={"false"}/>
            </div> */}
            {/* <div className={'wap-about-subject'}>
                <div className={'wap-about-subject-container'}>
                    <img src={subject} alt=""/>
                </div>
            </div> */}
            <div className={'wap-about-subject-container'}>
                <img src={aboutMobile} alt="" draggable={"false"}/>
            </div>
            <div className={'wap-about-subject-container'}>
                <img src={aboutJs} alt="" draggable={"false"}/>
            </div>
            <div className={'wap-about-subject-container'}>
                <img src={aboutM} alt="" draggable={"false"}/>
            </div>
            <div className={'wap-about-subject-container'}>
                <img src={aboutCp} alt="" draggable={"false"}/>
            </div>
            <div className={'wap-about-subject-container'}>
                <img src={chatme} alt="" draggable={"false"}/>
            </div>
            {/* <div className={'wap-about-partners'}>
                <img src={partners} alt=""/>
            </div>
            <div className={'wap-contact-info-container'}>
                <div className={'wap-contact-info-detail'}>
                    <ul className={'wap-contact-info-list'}>
                        {
                            contactList.map((item, index) =>
                                <li key={index}>
                                    <p>{item.title}</p>
                                    <p>{item.detail}</p>
                                </li>
                            )                            }
                    </ul>
                    <h1>联系我们</h1>
                </div>
                <img src={contact} alt="" draggable={"false"}/>
            </div> */}
        </div>
    )
}

export default About
