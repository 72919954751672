import qrcode from '../../assets/images/qrcode.png'
import logo from '../../assets/images/logoWhite.png'
import './footer.scss'
const Footer = () => {

  return (
    <footer className={'footer'}>
      <div className={'footerLeft'}>
        <img src={logo} alt=""/>
        <p>
          上海焕泽信息技术有限公司 <br/>
          联系我们：13564208710 <br/>
          业务合作：xuduozhi@shuhengio.com <br/>
          Copyright © 上海焕泽信息技术有限公司, All Rights Reserved.<br/>
          <a href="https://beian.miit.gov.cn/">沪ICP备2021014392号-1</a></p>
      </div>
      <div className={'footerRight'}>
        <img src={qrcode} alt=""/>
        <p>微信公众号</p>
      </div>
    </footer>
  )
}

export default Footer
