import { useState, useEffect } from "react";
import tabIcon from '../../assets/icon/tab'
import './tabBar.scss'
import {Link, useHistory} from "react-router-dom";
const TabBar = () => {
  const history = useHistory()
  const tabs = [
    {
      key: '/about',
      path: '/about',
      title: '关于焕泽',
      icon: 'about',
    },
    {
      key: '/content',
      path: '/content',
      title: '产品与服务',
      icon: 'service',
    },
    {
      key: '/case',
      path: '/case/index',
      title: '合作案例',
      icon: 'cases',
    },
    {
      key: '/join',
      path: '/join',
      title: '加入焕泽',
      icon: 'join',
    },
  ]
  const [currentPath, setCurrentPath] = useState(window.location.pathname === '/' ? '/about' : window.location.pathname )
  // const [currentPath, setCurrentPath] = useState(window.location.pathname)
  useEffect(() => {
    const subPath = ['/content', '/content?id=dataValue','/content?id=ulId1','/content?id=ulId4',  ]
    if(subPath.includes(window.location.pathname)){
      setCurrentPath(subPath[0])
    } else {
      setCurrentPath(window.location.pathname)
    }
    history.listen(location => {
      if(subPath.includes(location.pathname)){
        setCurrentPath(subPath[0])
      } else {
        setCurrentPath(location.pathname)
      }
    })
  }, [])

  const goPage = (key) => {
    history.push(key)
  }

  return(
    <div className={'tab-bar'}>
      {
        tabs.map((item, index) =>
          <div key={item.key} className={`tab-item ${currentPath.startsWith(item.key) ? 'tab-bar-item-focused' : ''}`} onClick={() => goPage(item.path)}>
            <img src={ currentPath.startsWith(item.key) ? tabIcon[`${item.icon}_focused`] : tabIcon[item.icon]} alt=""/>
            <p className={'tab-title'}>{item.title}</p>
          </div>
        )
      }
    </div>
  )
}

export default TabBar
