import {useEffect, useState} from 'react'
import banner from '../../../assets/images/join/banner.png'
import subject from '../../../assets/images/join/subject.png'
import location from '../../../assets/icon/location.png'
import doc from '../../../assets/icon/doc.png'
import clock from '../../../assets/icon/clock.png'
import qrcode from '../../../assets/images/qrcode.png'
import location_focused from '../../../assets/icon/location_focused.png'
import doc_focused from '../../../assets/icon/doc_focused.png'
import clock_focused from '../../../assets/icon/clock_focused.png'
import hi from '../../../assets/icon/hi.png'
import './join.scss'
import Apis from '../../../utils/apis'
const Join = ({ isMobile = false }) => {
  const [list, setList] = useState([])
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [info, setInfo] = useState({})
  useEffect(() => {
    getJdList()
  }, []);

  const getJdList = async () => {
    try{
      const res = await Apis.Jd.GetList()
      if(res.source){
        setList(res.source || [])
      }
    }catch (e){

    }
  }

  useEffect(() => {
      if(list.length === 0) return null
      const activeInfo = list[currentIndex] || {}
      setInfo(Object.assign({}, activeInfo, {desc: activeInfo.desc.split(','), requirements:  activeInfo.requirements.split(',')}))
  }, [currentIndex, list])

    const { title = '', content = '', desc = [], range = '', region = '', requirements = [], subTitle } = info

  return(
    <div className={'join'}>
      <img src={banner} alt="" className={'banner'} draggable={"false"}/>
      <div className={'subject'}>
        <img src={subject} alt="" draggable={"false"}/>
      </div>
      <div className={`container ${isMobile ? 'wap-join-container' : ''}`}>
        <ul className={'list'}>
          {
            list.map((item, index) =>
            {
              const { region, title, subTitle, content, range } = item
              return (
                <li className={'listItem'} key={index} onClick={() => setCurrentIndex(index)}>
                  <p className={'position'}>{title}</p>
                  <p className={'experience'}>{subTitle}</p>
                  <div className={'info'}>
                    <div className={'info-list'}>
                      <div className={'info-list-item'}>
                        <img src={ currentIndex === index ? location_focused : location } alt=""/>
                        <span>{region}</span>
                      </div>
                      <div className={'info-list-item'}>
                        <img src={ currentIndex === index ? doc_focused : doc } alt=""/>
                        <span>{content}</span>
                      </div>
                      <div className={'info-list-item'}>
                        <img src={ currentIndex === index ? clock_focused : clock } alt=""/>
                        <span>{range}</span>
                      </div>
                    </div>
                    <img src={hi} alt="" className={'hi'}/>
                  </div>
                </li>
              )
            }
            )
          }
        </ul>
        {
          list.length > 0 ?
          <div className={'detail'}>
          <div className={'detail-top'}>
            <div className={'detail-top-left'}>
              <p className={'position'}>{title}</p>
              <p className={'experience'}>{subTitle}</p>
              <div className={'info'}>
                <div className={'info-list'}>
                  <div className={'info-list-item'}>
                    <img src={location_focused} alt=""/>
                    <span>{region}</span>
                  </div>
                  <div className={'info-list-item'}>
                    <img src={doc_focused} alt=""/>
                    <span>{content}</span>
                  </div>
                  <div className={'info-list-item'}>
                    <img src={clock_focused} alt=""/>
                    <span>{range}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={'detail-top-right'}>
              <img src={qrcode} alt=""/>
              <p>扫描添加微信</p>
            </div>
          </div>
          <div className={'detail-bottom'}>
            <p className={'title'}>工作职责:</p>
            <ul className={'content-list'}>
              {
                desc.map((item, index) =>
                  <li key={index}>{`${index+1}、${item}`}</li>
                )
              }
            </ul>
            <p className={'title'}>岗位要求:</p>
            <ul className={'content-list'}>
              {
                requirements.map((item, index) =>
                  <li key={index}>{`${index+1}、${item}`}</li>
                )
              }
            </ul>
          </div>
        </div>
              :
              <h1 style={{ textAlign: 'center', alignSelf: 'center', width: '100%', marginTop: 80 }}> 当前暂无招聘信息</h1>
        }
      </div>
    </div>
  )
}

export default Join
