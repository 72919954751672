import banner from '../../../../assets/images/service/search/banner.png'
import subject from '../../../../assets/images/service/search/subject.png'
import one from '../../../../assets/images/service/search/1.png'
import BG from '../../../../assets/images/service/search/bg.png'
import two from '../../../../assets/images/service/search/2.png'
import './search.scss'

const funnels = ['Aware 引起注意', 'Appeal 产生关注', 'Ask 主动了解', 'Act 购买决策', 'Advocate \n 口碑传播']

const Search = ({isMobile = false}) => {
    return (
        <div className={'search'}>
            <div className={'search-banner-container'}>
                <div className={'search-banner-title-container'}>
                    <div className={'search-banner-title'}>
                        <p className={'title'}>渗透搜索全域路径</p>
                        <p className={'subtitle'}>帮助品牌打造全网矩阵式营销</p>
                    </div>
                </div>
                <img src={banner} alt="" className={'banner'} draggable={"false"}/>
            </div>
            <div className={'subject'}>
                <img src={subject} alt="" draggable={"false"}/>
            </div>
            <div className={`container ${isMobile ? 'wap-container' : ''}`}>
                <p>
                    洞察用户搜索行为 <br/>
                    布局在各个搜索节点 <br/>
                    从单纯的品牌到赢得用户信任 <br/>
                    全方位的为品牌获取目标用户<br/>
                    提升品牌声量
                </p>
                <img src={one} alt="" draggable={"false"}/>
                <div className={'funnel'}>
                    <div className={'funnel-container'}>
                        <p>搜索触达人群</p>
                        <ul className={'funnel-list'}>
                            {
                                funnels.map((item, index) =>
                                    <li>{item}</li>
                                )
                            }
                        </ul>
                        <div className={'funnel-bg'}>
                            <img src={BG} alt=""/>
                        </div>
                        <p>不同需求阶段</p>
                    </div>
                </div>
                <img src={two} alt="" draggable={"false"} className={'last-img'}/>
            </div>
        </div>
    )
}

export default Search
