import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import NavHeader from '../../components/header/Header'
import Footer from "../../components/footer";
import Home from "../views/home";
import About from "../views/about";
import Search from "../views/service/search";
import Content from "../views/service/content";
import Private from "../views/service/private";
import Media from "../views/service/media";
import Join from "../views/join";
import Case from "../views/case";
const RouterContainer = () => {
 const [currentPath, setCurrentPath] = useState(window.location.pathname === '/' ? '/home' : window.location.pathname )
  return (
    <div className={ currentPath.startsWith('/home') ? 'homeContainer' : ''}>
      <Router>
        <NavHeader setCurrentPath={setCurrentPath} currentPath={currentPath}/>
        <Switch>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/about" component={About}/>
          <Route path="/case/:name" component={Case}/>
          <Route path="/search" component={Search}/>
          <Route path="/content" component={Content}/>
          <Route path="/private" component={Private}/>
          <Route path="/media" component={Media}/>
          <Route exact path="/join" component={Join}/>
          <Redirect from={'/'} to={'/home'}/>
        </Switch>
        { !currentPath.startsWith('/home') && <Footer /> }
      </Router>
    </div>
  )
}

export default RouterContainer
