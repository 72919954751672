import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./header.scss";

const routeMap = [
  {
    routeName: "首页",
    routePath: "/home",
  },
  {
    routeName: "关于焕泽",
    routePath: "/about",
  },
  {
    routeName: "产品与服务",
    routePath: "/content",
    subMenu: [
      {
        routeName: "内容价值",
        routePath: "/content?id=dataValue",
      },
      {
        routeName: "数据价值",
        routePath: "/content?id=ulId1",
      },
      {
        routeName: "渠道价值",
        routePath: "/content?id=ulId4",
      },
    ],
    // subMenu: [
    //   {
    //     routeName: '全域搜索',
    //     routePath: '/search',
    //   },
    //   {
    //     routeName: '内容营销',
    //     routePath: '/content',
    //   },
    //   {
    //     routeName: '私域营销',
    //     routePath: '/private',
    //   },
    //   {
    //     routeName: '媒体资源',
    //     routePath: '/media',
    //   },
    // ]
  },
  {
    routeName: "合作案例",
    routePath: "/case/index",
  },
  {
    routeName: "加入焕泽",
    routePath: "/join",
  },
];
// const routerPush = (item) => {
//   // const history = useHistory();
//   window.location.href(item.routePath);
//   return false;
// };
const Header = ({ isHome = true, setCurrentPath, currentPath }) => {
  const history = useHistory();
  const [current, setCurrent] = useState(-1);
  useEffect(() => {
    history.listen((location) => {
      setCurrentPath(location.pathname);
    });
  }, []);

  useEffect(() => {
    routeMap.map((item, index) => {
      if (item.routePath.startsWith(currentPath)) {
        setCurrent(index);
      } else {
        if (item.subMenu) {
          const res = item.subMenu.filter((val) =>
            val.routePath.startsWith(currentPath)
          );
          if (res.length > 0) {
            setCurrent(index);
          }
        }
      }
    });
  }, [currentPath]);

  return <WindowHeader currentI={current} />;
};

const WindowHeader = ({ currentI }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const onMenu = (i) => {
    setCurrentIndex(i);
  };

  return (
    <header className={"windowHeader"}>
      <div className={"logo"}>
        <img src={logo} alt="" />
      </div>
      <ul className={"navList"}>
        {routeMap.map((item, index) => (
          <li
            className={"navItem"}
            key={index}
            onMouseEnter={() => onMenu(index)}
          >
            {item.hasOwnProperty("subMenu") && item.subMenu.length > 0 ? (
              <div
                className={"subMenuContainer"}
                onMouseEnter={() => onMenu(index)}
                onMouseLeave={() => setCurrentIndex(-1)}
              >
                {/* <a
                  className={currentI === index ? "activeLink" : ""}
                  style={{ cursor: "pointer" }}
                >
                  {item.routeName}
                </a> */}
                <Link
                  className={currentI === index ? "activeLink" : ""}
                  to={item.routePath}
                >
                  {item.routeName}
                </Link>
                <ul
                  className={`subMenu ${
                    currentIndex === index ? "show" : "hide"
                  }`}
                >
                  {item.subMenu &&
                    item.subMenu.map((pie, i) => (
                      <li className={"menuItem"} key={i}>
                        <Link to={pie.routePath}>{pie.routeName}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <Link
                className={currentI === index ? "activeLink" : ""}
                to={item.routePath}
              >
                {item.routeName}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </header>
  );
};

export default Header;
