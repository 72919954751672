import axios from 'axios';
import qs from 'qs'

const dev = process.env.NODE_ENV === 'development'
console.log(dev, 'development')
const options = {
  url: '/',
  timeout: 8000,
};

const session = axios.create({
  timeout: options.timeout,
  baseURL: options.url,
});

session.interceptors.request.use(async (config) => {
  if (localStorage.authorization) {
    config.headers.authorization = localStorage.authorization;
  }

  if (config.data) {
    config.data = qs.stringify(config.data);
  }

  return config;
});


session.interceptors.response.use(
  async (response) => {
    if (response.headers.authorization?.startsWith('signout')) {
      localStorage.authorization = '';
    } else if (response.headers.authorization) {
      localStorage.authorization = response.headers.authorization;
    }

    // @ts-ignore
    if (dev) {
      console.log(
        `[session][->][${response.config.url}][${response.status}][->]`,
        {
          response: response,
          data: response.data,
        },
      );
    }

    return response.data;
  },
  async (e) => {
    // @ts-ignore
    if (dev) {
      if (e.response?.data) {
        console.log(
          `[session][<-][${e.response.config.url}][${e.response.status}][<-]`,
          e.response?.data,
        );
      } else {
        console.warn(e);
      }
    }

    if (e.response?.status === 401) {
      // return history.push('/signout');
    }

    return e.response?.data
      ? Promise.resolve(e.response?.data || { code: -99, msg: 'unknow' })
      : Promise.resolve({ code: -999, msg: '无法连接到服务器' });
  },
);

export default session
