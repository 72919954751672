import banner from '../../../../assets/images/service/media/banner.png'
import one from '../../../../assets/images/service/media/1.png'
import two from '../../../../assets/images/service/media/2.png'
import './media.scss'
const Media = ({isMobile = false}) => {


  return (
    <div className={'media'}>
      <img src={banner} alt="" className={'banner'} draggable={"false"}/>
      <div className={`container ${isMobile ? 'wap-container' : ''}`}>
        <img src={one} alt="" draggable={"false"}/>
        <img src={two} alt="" draggable={"false"}/>
      </div>
    </div>
  )
}

export default Media
